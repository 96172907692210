import React from "react";

const skillContent = [
  {
    name: "React Native",
    percent: "85",
    level: "Confirmé",
  },
  {
    name: "React JS",
    percent: "75",
    level: "Confirmé",
  },
  {
    name: "Node JS (Confortable avec les API / BDD / ORM)",
    percent: "65",
    level: "Intermédiaire",
  },
  {
    name: "HTML / CSS",
    percent: "85",
    level: "Confirmé",
  },
  {
    name: "MySQL",
    percent: "70",
    level: "Intermédiaire",
  },
];

const Skills = () => {
  return (
    <>
      <div className="skill-wrapper">
        {skillContent.map((skill, i) => (
          <div className="skill-lt" key={i}>
            <h6>{skill.name}</h6>
            <span>{skill.percent}%</span>
            <span
              style={{
                fontSize: 15,
                color: "#333",
                paddingTop: 30,
                fontWeight: 400,
              }}
            >
              {skill.level}
            </span>
            <div className="skill-bar">
              <div
                className="skill-bar-in"
                style={{ width: skill.percent + "%" }}
              ></div>
            </div>
          </div>
          // End skill-lt
        ))}
      </div>
    </>
  );
};

export default Skills;
