import React from "react";
// import TextLoop from "react-text-loop";
import Fade from "react-reveal/Fade";

const sliderContent = {
  name: "Yoann Le Hir",
  description: `Je suis spécialisé dans le développement d'applications mobiles cross-platform avec React-native. J'ai également des compétences dans le développement web avec ReactJS et back end avec NodeJS.`,
  description2: `  
  Diplômé d'école de commerce, j’ai choisi de m’orienter vers un domaine pour lequel j’éprouve un vif intérêt, la programmation informatique. J'accompagne maintenant les entreprises dans le développement de leur projet sur mobile, toujours dans une démarche orientée business et utilisateurs.
`,
  btnText: " Mes réalisations",
  btnText2: "Me contacter",
};

const SliderFour = () => {
  return (
    <>
      {/*  Home Banner */}
      <section
        id="home"
        className="home-banner home-banner-two slider-four"
        // style={{
        //   backgroundImage: `url(${
        //     process.env.PUBLIC_URL + "img/slider/test3.svg"
        //   })`,
        // }}
      >
        <div className="container">
          <div className="row full-screen align-items-center">
            <div className="col-lg-6">
              <div className="type-box">
                <Fade bottom>
                  {/* <h6>Hello there...</h6> */}
                  <h1 className="font-alt">{sliderContent.name}</h1>
                  <p className="loop-text lead"> Développeur mobile</p>

                  <p className="desc">{sliderContent.description}</p>
                  <p className="desc">{sliderContent.description2}</p>
                  {/* <p className="desc">{sliderContent.description3}</p> */}
                  <div className="d-flex btn-wrapper">
                    <a className="px-btn px-btn-theme mr-4" href="#work">
                      {sliderContent.btnText}
                    </a>
                    <a className="px-btn btn-outline" href="#contactus">
                      {sliderContent.btnText2}
                    </a>
                  </div>
                </Fade>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="about-me">
                <div className="img mt-4">
                  <div className="img-in">
                    <img
                      src={
                        require("../../../src/assets/images/about/yoann.jpg")
                          .default
                      }
                      alt="about"
                    />
                  </div>
                  {/* <Social /> */}
                  {/* End social icon */}
                </div>
                {/* End img */}

                {/* End info */}
              </div>
              {/* End about-me */}
            </div>
          </div>
        </div>
        {/* End Container*/}
        <div className="go-to go-to-next">
          <a href="#about">
            <span></span>
          </a>
        </div>
      </section>

      {/* End Home Banner  */}
    </>
  );
};

export default SliderFour;
